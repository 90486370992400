<template>
  <div id="plotly-charts" ref="chart" class="w100 h100 plotly-mortality-chart"></div>
</template>

<script>
import Plotly from 'plotly.js-basic-dist-min';
import uniq from 'lodash/uniq';

const config = {
  displaylogo: false,
  editable: false,
  displayModeBar: false,
  responsive: true // to make the chart responsive, make sure it's not messing up anything
};
const defaultTitleFont = {
  family: 'DINNextLTPro-Regular',
  size: 14,
  color: '#363534b8'
};
const defaultTickFont = {
  family: 'DINNextLTPro-Regular',
  size: 14,
  color: '#363534b8'
};

export default {
  name: 'MortalityPopulationPyramid',
  props: {
    plot: {
      type: [Object, Array],
      required: true
    }
  },
  mounted() {
    this.renderPopulationPyramid();
  },
  methods: {
    renderPopulationPyramid() {
      const male = this.plot.filter(row => row.sex === 'm');
      const female = this.plot.filter(row => row.sex === 'f');
      const labels = uniq(this.plot.map(row => row.agecat.replaceAll('[', '').replaceAll(')', '').replaceAll(']', '').replaceAll(',', ' - ')));
      const maleTrace = {
        x: male.map(row => row.p * 100),
        y: labels,
        name: this.$tc('values.male', 1),
        marker: {
          color: '#399bff'
        },
        type: 'bar',
        orientation: 'h'
      };
      const femaleTrace = {
        x: female.map(row => -(row.p * 100)),
        y: labels,
        name: this.$tc('values.female', 1),
        marker: {
          color: '#ff7f7f'
        },
        type: 'bar',
        orientation: 'h'
      };

      const data = [maleTrace, femaleTrace];
      const layout = {
        showlegend: true,
        hovermode: false,
        legend: {
          x: 1,
          xanchor: 'right',
          y: 1,
          orientation: 'h'
        },
        margin: {
          t: 0,
          b: 80,
          l: 80
        },
        xaxis: {
          title: this.$t('components.titles.percentOfTotalPopulation'),
          showgrid: false,
          titlefont: defaultTitleFont,
          tickfont: defaultTickFont,
          type: 'linear',
          range: [-20, 20],
          ticktext: [20, 15, 10, 5, 5, 10, 15, 20],
          tickvals: [-20, -15, -10, -5, 5, 10, 15, 20],
          zeroline: false
        },
        yaxis: {
          showgrid: false,
          title: '',
          tickfont: defaultTickFont
        },
        barmode: 'relative',
        bargap: 20
      };

      Plotly.newPlot('plotly-charts', data, layout, config);
    }
  }
};
</script>

<style lang="scss">
</style>
